import React from 'react';
import {
  Container,
  Typography,
  Grid,
  makeStyles,
  Card,
  CardContent
} from '@material-ui/core'
import { Link } from 'gatsby'
import Layout from "../components/layout"
import { createContent } from "../common/Theme"
import FadeIn from "../components/FadeIn"
import Button from "../components/button"
import ContactUs from "../components/contactUs"

import '../assets/styles/pages/about.scss'

const useStyles = makeStyles({
  projectsServicesContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: '100% !important',
  },
  card: {
    marginBottom: 0
  },
  paragraph: {
    marginBottom: '1rem'
  }
});

const AboutPage = () => {
  const content = createContent();

  const classes = useStyles();

  return (
    <Layout>
      <section id='about-section'>
        <Container classes={{root: content.content}}>
          <div className='inner-container'>
            <Grid container spacing={6}>
              <Grid item md={4} sm={12} xs={12} className='side-heading-header'>
                <Typography variant='h3'>People-centric digital agency</Typography>
              </Grid>
              <Grid item md={8} sm={12} xs={12} className='side-heading-content'>
                <Typography classes={{root: classes.paragraph}} className='text-large'>
                  Syntactful is a people-centric digital agency where creativity is constantly flowing.

                  We create bespoke digital products and software with a user-first mentality.
                  It is our mission to create solutions that enrich people’s lives and have a meaningful impact.
                </Typography>
                <Typography classes={{root: classes.paragraph}} className='text-large'>
                  People are at the center of every business. This notion rings true for both employees and customers. By applying a user-first approach to design and development we empower employees to create real change and create meaningful interactions with customers.
                </Typography>
                <Typography className='text-large'>
                  To us, applying a people-centric approach to design and development is not an option, it is a must.
                </Typography>
              </Grid>
            </Grid>
          </div>
        </Container>
      </section>
      <section id='partner-section'>
        <Container classes={{root: content.content}}>
          <Typography variant='h3' color='textSecondary'>Why partner with us?</Typography>
          <Grid container spacing={8}>
            <Grid item md={3} sm={6}>
              <FadeIn>
                <Typography variant='h4' color='textSecondary'>Creative thinkers</Typography>
                <Typography className='text-grey-dark'>
                  The power of imagination and the ability to take that imagination and turn it into a reality is what defines us as human beings.
                  We love to come up with creative solutions that fix real-world problems.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={3} sm={6}>
              <FadeIn>
                <Typography variant='h4' color='textSecondary'>User-first approach</Typography>
                <Typography className='text-grey-dark'>
                  We believe people, not software, should be the focus of every organization.
                  Every solution we create is in service of people.
                  Our primary goal when we develop a software solution is to enable people to spend more time on meaningful, purposeful work.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={3} sm={6}>
              <FadeIn>
                <Typography variant='h4' color='textSecondary'>Long-term partners</Typography>
                <Typography className='text-grey-dark'>
                  We care for our clients and our projects.
                  We understand that projects evolve over time so we create solutions that are able to grow with you and your business while establishing
                  a long-term partnership.
                </Typography>
              </FadeIn>
            </Grid>
            <Grid item md={3} sm={6}>
              <FadeIn>
                <Typography variant='h4' color='textSecondary'>Flexible solutions</Typography>
                <Typography className='text-grey-dark'>
                  No matter the timing, budget or scope of a project, we always look for the optimal solution for every problem.
                  We also don’t subscribe to the idea of vendor lock-in.
                  Technology should create freedom, not constraints.
                </Typography>
              </FadeIn>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section id='projects-services-section'>
        <Container classes={{root: classes.projectsServicesContainer}}>
          <Grid container spacing={0}>
            <Grid item md={6} sm={12}>
              <Card elevation={0} id='projects-card' className='projects-services-card' classes={{root: classes.card}}>
                <Link to='/projects' className='projects-services-link'>
                  <div id='projects-card-image' className='projects-services-card-image' />
                  <CardContent>
                    <Button text='Our projects' variant='secondary' hoverable={false} margin={false} />
                  </CardContent>
                </Link>
              </Card>
            </Grid>
            <Grid item md={6} sm={12} id='services-grid-item'>
              <Card elevation={0} id='services-card' className='projects-services-card' classes={{root: classes.card}}>
                <Link to='/services' className='projects-services-link'>
                  <div id='services-card-image' className='projects-services-card-image' />
                  <CardContent>
                    <Button text='Our services' variant='secondary' hoverable={false} margin={false} />
                  </CardContent>
                </Link>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </section>

      <ContactUs mt />
      {/*<section>*/}
      {/*  <Container classes={{root: content.content}}>*/}
      {/*    <Typography>Why choose custom software?</Typography>*/}
      {/*  </Container>*/}
      {/*</section>*/}
    </Layout>
  )
}

export default AboutPage;
